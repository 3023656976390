$white: #ffffff;
$black: #000000;
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  background-color: $white;
  font-family: "DM Sans";
}

// TYPOGRAPHY ------------------------

h1,
p {
  margin: 0;
}

a {
  word-wrap: break-word;
  color: $black;
  font-style: normal;
  font-weight: 700;
  // transition: all 0.3s ease-in-out;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.48px;
  text-decoration: none;
  @media only screen and (min-width: 768px) {
    line-height: 40px;
    font-size: 32px;
    letter-spacing: -0.64px;
  }

  &:hover {
    cursor: pointer;
    // text-decoration: underline;
    color: #e42a1d;
  }
}

.glitch-item:hover {
  text-decoration: none;
  color: #e42a1d;
}

a.moze {
  font-size: 16px;
}

h1 {
  color: $black;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: $black;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -0.96px;

  @media only screen and (min-width: 768px) {
    font-size: 64px;
    line-height: 64px;
    letter-spacing: -0.96px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: -0.96px;
  }
  @media only screen and (min-width: 1300px) {
    font-size: 104px;
    line-height: 104px;
    letter-spacing: -2.24px;
  }
}

p.title {
  color: $black;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.48px;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.64px;
  }
}

p.text {
  color: $black;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.48px;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.64px;
  }

  &.bold {
    font-weight: 700;
  }
}

p.text-small-title {
  color: $black;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.36px;

  &.bold {
    font-weight: 700;
  }

  .colored {
    color: #e42a1d;
  }

  @media only screen and (min-width: 768px) {
    line-height: 36px;
    font-size: 24px;
    letter-spacing: -0.48px;
  }
}

p.text-small {
  color: $black;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.4px;

  &.bold {
    font-weight: 700;
  }

  &.translation {
    font-style: italic;
    margin-top: -24px;
  }

  & > a {
    font-size: 24px;
    line-height: 36px;
    cursor: pointer !important;
  }
  @media only screen and (min-width: 768px) {
    line-height: 36px;
    font-size: 24px;
    letter-spacing: -0.48px;
  }
}

p.text-xs {
  color: $black;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
  letter-spacing: -0.28px;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.32px;
  }

  &.bold {
    font-weight: 700;
  }
}

.container {
  position: relative;
  background-color: $white;
  width: 100%;
  color: $black;

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 24px;

    @media only screen and (min-width: 768px) {
      padding: 80px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $black;
    }

    &.hero {
      height: 100%;
      @media only screen and (min-width: 768px) {
        height: 100vh;
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 40px;
      }
    }

    .content {
      max-width: 1600px;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 40px;
      @media only screen and (min-width: 768px) {
        gap: 80px;
      }
      .col2 {
        display: flex;
        flex-direction: column;
        gap: 40px;
        .wrapper {
          flex-direction: column;
          display: flex;
          gap: 24px;
          @media only screen and (min-width: 768px) {
            flex-direction: row;
            gap: 40px;
            justify-content: space-between;
          }

          &.item {
            &:not(:last-child) {
              padding-bottom: 40px;
              border-bottom: 1px solid $black;
            }
          }

          .left {
            width: 100%;

            @media only screen and (min-width: 768px) {
              max-width: 520px;
              min-width: 160px;
              display: flex;
              flex: 1;
            }

            &.copy {
              gap: 2px;
            }

            &.sticky {
              @media only screen and (min-width: 768px) {
                position: sticky;
                top: 80px;
                height: 100%;
              }
            }
          }

          .right {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 24px;
            @media only screen and (min-width: 768px) {
              max-width: 720px;
              gap: 40px;
            }
          }
        }
      }
    }
  }
  .footer {
    padding: 40px 24px;
    @media only screen and (min-width: 768px) {
      padding: 40px 80px;
    }
    .wrapper.item {
      flex-direction: row !important;
      justify-content: space-between;

      @media only screen and (max-width: 380px) {
        flex-direction: column !important;
      }

      .left {
        display: flex;
        align-items: center;

        @media only screen and (max-width: 768px) {
          width: unset !important;
        }
      }

      .right {
        display: flex;
        flex-direction: row !important;
        @media only screen and (max-width: 768px) {
          width: unset !important;
        }

        .footer-lang {
          display: flex;
          align-items: center;

          li {
            list-style: none;

            a {
              color: $black;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17.5px;
              letter-spacing: -0.28px;

              display: inline-block;

              text-decoration: none;
              border-bottom: 0.08em solid;
              &:hover {
                opacity: 0.8;
              }
              html[lang="en"] &[data-lang="en"],
              html[lang="it"] &[data-lang="it"] {
                border-bottom-width: 0;
                pointer-events: none;
                opacity: 0.4;
                display: none;
              }

              @media only screen and (min-width: 768px) {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.32px;
              }
            }
          }
        }
      }
    }
  }
}

// IMAGE WRAPPER CANVAS ------------------------

.contentImage {
  width: 100%;
  aspect-ratio: 4/3;
  max-height: 720px;
  height: 100%;
  position: relative;

  @media only screen and (min-width: 1380px) {
    max-height: 1020px;
  }
}

.canvas-wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  max-height: 720px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);

  @media only screen and (min-width: 1380px) {
    max-height: 1020px;
  }
}

.no-js .canvas-wrap {
  opacity: 1;
}

// LENIS -------------------------------------

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

// MODAL LANG

.modal-lang {
  position: fixed;
  background: #fff;
  width: 100%;
  right: auto;
  width: 100%;

  padding: 35px 30px 25px;
  position: fixed;
  z-index: 2;
  background: #fff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  max-width: 450px;
  bottom: 0;
  left: 25px;

  @media only screen and (max-width: 768px) {
    left: 0;
    max-width: 100%;
    width: 100%;
  }

  .modal-lang-switch {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  a {
    display: inline-block;
    margin-right: 16px;
    padding-bottom: 7px;
    margin-bottom: 10px;
    line-height: 17px;
    font-weight: 500;
    font-size: 17px;

    &.underline {
      position: relative;
      display: inline-block;

      margin-right: 15px;

      font-size: 1.1em;
      text-decoration: none;

      &:hover {
        &::after {
          outline-width: 0;
          width: 0;
          background-color: #e42a1d;
        }
      }

      &::after {
        content: " ";
        position: absolute;
        bottom: 0;
        background-color: #000a14;
        left: 0;
        width: 100%;
        height: 2px;
        -webkit-transition: width 0.2s cubic-bezier(0, 0.71, 0.41, 1);
        -o-transition: width 0.2s cubic-bezier(0, 0.71, 0.41, 1);
        transition: width 0.2s cubic-bezier(0, 0.71, 0.41, 1);
        will-change: width;
      }
    }
    &.close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 20px;
      margin: 0;
      font-size: 2.2rem;
      border-bottom: none;
    }
  }
  h4 {
    position: relative;
    font-size: 24px;
    line-height: 28px;
  }
}
